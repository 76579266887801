import omitBy from 'lodash-es/omitBy';
import { serialize } from 'object-to-formdata';

import type {
  AnalyticsSourceFilters,
  BatchOrdersRequest,
  BatchOrdersResponse,
  BillingLinkParams,
  BillingLinkResponse,
  BrandingResponse,
  BridgeToken,
  BridgeTokenParams,
  BridgeTokenSettings,
  CategorizedCompaniesType,
  CategorizedCompaniesWidget,
  CompanyResponse,
  CreateBridgeUserParams,
  CreateBridgeUserResponse,
  CreateMemberResult,
  CreateOrderGroupParams,
  CreateScoringAttributesReportResponse,
  CreateSupportTicketRequest,
  CreateWebhookParams,
  CurrentCompanyResponse,
  DataSource,
  DataSourcesParams,
  DataSourcesResponse,
  EditOrderRequest,
  EnvType,
  GetBridgeUsersParams,
  GetBridgeUsersResponse,
  GetPopularCompaniesListIdResponse,
  GetTemplateResponse,
  GetTemplatesRequest,
  GetTemplatesResponse,
  GetTwilioSettings,
  ImpersonateResponse,
  ListResult,
  LogItem,
  LogsFilters,
  Member,
  OptionsResult,
  OrderFileResponse,
  OrderGroup,
  OrderGroupPagination,
  OrderGroupParams,
  PagingFilters,
  PerformanceReportsParams,
  PerformanceReportsResponse,
  ProfileSuggestResponse,
  ProviderStatusesResponse,
  ProvidersListRequest,
  ProvidersListResponse,
  RefreshOrderRequest,
  RefreshOrderResponse,
  ReplyToSupportTicketRequest,
  ReportParams,
  RequestCoverageParams,
  Role,
  ScoringAttributesFileResponse,
  ScoringAttributesReportResponse,
  SubcompaniesResponse,
  SupportTicketCommentsResponse,
  SupportTicketInfoResponse,
  SupportTicketsRequest,
  SupportTicketsResponse,
  SupportTicketsResult,
  TaskItem,
  TaskItemProvider,
  TasksFilters,
  TemplateSettings,
  TruvSuggestionType,
  UpdateCompanyRequest,
  UpdateMemberResult,
  UpdateTwilioSettings,
  UploadAttachmentResponse,
  UploadedDocumentInfo,
  UsageReportsResponse,
  UserFilters,
  UserItem,
  UserKeysResponse,
  UserProperties,
  VerificationReport,
  VerificationType,
  WebhookItem,
  WidgetSettings,
} from 'api/types';
import { PRODUCTS_WITH_ACCOUNT } from 'api/types';
import type { BridgeTokenResponse } from 'components/Emulator/utils/types';
import type { ProfileResponse } from 'entities/user/types';
import { isFalsy } from 'shared/lib/data/isFalsy';

import type { BaseApiClient } from './BaseApiClient';
import { baseApiClient } from './BaseApiClient';

export type RefreshTaskResult = {
  task_id: string;
};

export type ImpersonateParams = {
  target: string;
  reason: string;
};

export type ProductTextsResponse = {
  created_at: string;
  field_name:
    | 'verification_email_header'
    | 'verification_email_footer'
    | 'verification_footer_text'
    | 'verification_header_text'
    | 'verification_title_text'
    | 'verification_expired_header'
    | 'verification_expired_text'
    | 'verification_success_header'
    | 'verification_success_text';
  id: string;
  notification_type: string;
  product_type: VerificationType;
  text: string;
  updated_at: string;
};

export const ProductionUserKeyRequiredError = new Error('ProductionUserKeyRequiredError');

export const coreBackendUrl = (import.meta.env.VITE_APP_CORE_API_URL as string) || 'https://prod.truv.com';

export const bridgeUrl = (import.meta.env.VITE_APP_BRIDGE_URL as string) || 'https://cdn.truv.com/bridge.js';

export class ApiClient {
  constructor(private client: BaseApiClient) {}

  getBillingLink = (params: BillingLinkParams) => {
    return this.client.post<BillingLinkResponse>('/v1/billing_link/', params);
  };

  getBrandingSettings = () => {
    return this.client.get<BrandingResponse>('/v1/branding-settings/');
  };

  getProductTexts = () => {
    return this.client.get<ProductTextsResponse[]>('/v1/product-texts/');
  };

  getCompany = () => {
    return this.client.get<CurrentCompanyResponse>('/v1/company/');
  };

  getCompanySettings = (id: string) => {
    return this.client.get<CompanyResponse>(`/v1/companies/${id}/`);
  };

  updateCompanySettings = (id: string, data: UpdateCompanyRequest) => {
    return this.client.patch<CompanyResponse>(`/v1/companies/${id}/`, data);
  };

  getProfile = () => {
    return this.client.get<ProfileResponse>('/v1/profile/');
  };

  getUsageReports = (params: { year: string } & Partial<PagingFilters>) => {
    return this.client.get<UsageReportsResponse>('/p/v1/usage-reports/', params);
  };

  getPerformanceReports = (params: PerformanceReportsParams) => {
    return this.client.get<PerformanceReportsResponse>('/v2/performance-reports/', omitBy(params, isFalsy));
  };

  getProfileSuggest = () => {
    return this.client.get<ProfileSuggestResponse>('/v1/profile/suggest/');
  };

  getLogs = (params: LogsFilters = { ordering: '', page_size: 10, page: 1 }, { signal }: Partial<RequestInit> = {}) => {
    return this.client.get<ListResult<LogItem>>('/v1/logs/', params, { signal });
  };

  getOptions = () => {
    return this.client.get<OptionsResult>('/v1/options/');
  };

  getProviders = () => {
    return this.client.get<TaskItemProvider[]>('/v1/providers/');
  };

  getTasks = (
    params: TasksFilters = { ordering: '', page_size: 10, page: 1 },
    { signal }: Partial<RequestInit> = {},
  ) => {
    return this.client.get<ListResult<TaskItem>>('/v1/tasks/', params, { signal });
  };

  getTask = (id: string) => {
    return this.client.get<TaskItem>(`/v1/tasks/${id}`);
  };

  getRefreshTask = (id: string) => {
    return this.client.get<TaskItem>(`/v1/refresh/tasks/${id}`);
  };

  getUserProfiles = (filters: UserFilters = { page_size: 10, page: 1 }) => {
    return this.client.get<ListResult<UserItem>>('/v1/profiles/', filters);
  };

  getUserProfile = (id: string) => {
    return this.client.get<UserItem>(`/v1/profiles/${id}`);
  };

  getReport = (params: ReportParams) => {
    return this.client.post<VerificationReport>(`/v1/reports/${params.product_type}/`, params);
  };

  refreshTask = (params: ReportParams) => {
    return this.client.post<RefreshTaskResult>('/v1/tasks/refresh/', params);
  };

  getUserKeys = () => {
    return this.client.get<UserKeysResponse>('/v2/user_keys/');
  };

  getProviderStatuses = () => {
    return this.client.get<ProviderStatusesResponse>('/v1/provider-statuses/', { days: 30 }); // TODO UX-1515
  };

  createBridgeUser = (data: CreateBridgeUserParams) => {
    return this.client.post<CreateBridgeUserResponse>('/p/v1/users/', data);
  };

  getBridgeUsers = (params: GetBridgeUsersParams) => {
    return this.client.get<GetBridgeUsersResponse>('/p/v1/users/', params);
  };

  createBridgeToken = async (
    userId: string,
    { type, mappingId, providerId, template, account, dataSources }: BridgeTokenParams,
  ) => {
    try {
      const requestParameters: BridgeTokenSettings = {
        product_type: type,
      };

      if (mappingId) {
        requestParameters.company_mapping_id = mappingId;
      }

      if (providerId) {
        requestParameters.provider_id = providerId;
      }

      if (template && template !== 'default') {
        requestParameters.template_id = template;
      }

      if (PRODUCTS_WITH_ACCOUNT.includes(type)) {
        requestParameters.account = { ...account };

        // Backend API return 400 error if bank_address is empty string
        // But no bank_address is allowed
        if (requestParameters.account.bank_address === '') {
          delete requestParameters.account.bank_address;
        }
      }

      if (dataSources) {
        requestParameters.data_sources = dataSources.split(',') as DataSource[];
      }

      return this.client.post<BridgeToken>(`/p/v1/users/${userId}/tokens/`, requestParameters);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      // TODO: move error codes to one file later
      if ((e as { code: string }).code === 'production_user_key_required') {
        throw ProductionUserKeyRequiredError;
      } else {
        throw e;
      }
    }
  };

  getBridgeTokenSettings = async (bridgeToken: string, { signal }: Partial<RequestInit> = {}) => {
    return this.client.request<BridgeTokenResponse>(`${coreBackendUrl}/v1/bridge-tokens/${bridgeToken}/`, {
      headers: { 'X-Bridge-Token': bridgeToken },
      signal,
    });
  };

  getCompanySuggests = async (bridgeToken: string, params: { query: string; product_type: VerificationType }) => {
    return this.client.request<TruvSuggestionType[]>(
      `${coreBackendUrl}/v1/company-mappings-search/`,
      { headers: { 'X-Bridge-Token': bridgeToken } },
      omitBy(params, isFalsy),
    );
  };

  getCategorizedCompanies = async (productType: VerificationType) => {
    return this.client.get<CategorizedCompaniesType[]>('/v1/categorized-companies/', {
      product_type: productType,
    });
  };

  getWidgetCategorizedCompanies = async (bridgeToken: string, listId?: string) => {
    const params = {
      format: 'json',
      popular_listing_id: listId,
    };

    return this.client.request<CategorizedCompaniesWidget[]>(
      `${coreBackendUrl}/v1/categorized-companies/`,
      {
        headers: { 'X-Bridge-Token': bridgeToken },
      },
      omitBy(params, isFalsy),
    );
  };

  getProvidersList = (bridgeToken: string, params: ProvidersListRequest) => {
    return this.client.request<ProvidersListResponse>(
      `${coreBackendUrl}/v1/company-mapping-providers/`,
      { headers: { 'X-Bridge-Token': bridgeToken } },
      params,
    );
  };

  createOrderGroup = async ({ order_group, employments, env, reports }: Partial<CreateOrderGroupParams>) => {
    const data = {
      ...order_group,
      ...(employments && !!employments.length ? { employers: employments } : undefined),
      reports,
      env,
    };

    try {
      const result = await this.client.post<OrderGroup>('/v1/order_groups/ ', data);

      return result;
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);

      // TODO: move error codes to one file later
      if ((e as { code: string }).code === 'production_user_key_required') {
        throw ProductionUserKeyRequiredError;
      } else {
        throw e;
      }
    }
  };

  updateBrandingSettings = async (data: Partial<BrandingResponse>) => {
    return this.client.patch<BrandingResponse>('/v1/branding-settings/', data);
  };

  updateProfile = async (data: Partial<ProfileResponse>) => {
    return this.client.patch<ProfileResponse>('/v1/profile/', data);
  };

  updateWidgetSettings = async (data: Partial<WidgetSettings>) => {
    return this.client.patch<WidgetSettings>('/v1/widget-settings/', data);
  };

  deleteLogo = async () => {
    return this.client.delete<WidgetSettings>('/v1/widget-settings/logo/');
  };

  uploadLogo = async (file: File) => {
    const formData = new FormData();

    formData.append('logo', file, file.name);

    return this.client.patch<WidgetSettings>('/v1/widget-settings/logo/', formData);
  };

  getWidgetSettings = async () => {
    return this.client.get<WidgetSettings>('/v1/widget-settings/');
  };

  getDataSources = async (params?: DataSourcesParams, { signal }: Partial<RequestInit> = {}) => {
    return this.client.get<DataSourcesResponse>('/v2/data-sources/', params, { signal });
  };

  getOrderGroups = async (data?: OrderGroupParams, { signal }: Partial<RequestInit> = {}) => {
    return this.client.get<OrderGroupPagination>('/v1/order_groups/', data, { signal });
  };

  getOrderGroup = async (order_group_id: string) => {
    return this.client.get<OrderGroup>(`/v1/order_groups/${order_group_id}/`);
  };

  cancelOrderGroup = async (order_group_id: string) => {
    return this.client.post<OrderGroup>(`/v1/order_groups/${order_group_id}/cancel/`, {});
  };

  cancelSubOrder = async (orderId: string, subOrderId: string) => {
    return this.client.put<OrderGroup>(`/p/v1/orders/${orderId}/employers/${subOrderId}/cancel`, {});
  };

  refreshOrderGroup = async (order_group_id: string, body: RefreshOrderRequest) => {
    return this.client.post<RefreshOrderResponse>(`/v1/order_groups/${order_group_id}/`, body);
  };

  downloadOrderGroupZip = (orderGroupId: string) => {
    return this.client.download<Blob>(
      `/v1/order_groups/${orderGroupId}/bulk_download_files/`,
      'POST',
      'application/zip',
    );
  };

  editOrderGroup = async (order_group_id: string, data: EditOrderRequest) => {
    return this.client.patch<OrderGroup>(`/v1/order_groups/${order_group_id}/`, data);
  };

  createBatchOrders = async (data: BatchOrdersRequest) => {
    const formData = serialize(data, {
      dotsForObjectNotation: true,
      noAttributesWithArrayNotation: true,
    });

    return this.client.post<BatchOrdersResponse>('/v1/bulk/order_groups/', formData);
  };

  getBatchOrders = async (id?: string) => {
    return this.client.get<BatchOrdersResponse>(`/v1/bulk/order_groups/${id}/`);
  };

  createBatchOrdersRun = async (id?: string) => {
    return this.client.post<BatchOrdersResponse>(`/v1/bulk/order_groups/${id}/run/`, {});
  };

  getOrderInvoice = async (orderId: string) => {
    return this.client.get<OrderFileResponse>(`/p/v1/orders/${orderId}/invoice/`);
  };

  getOrderConsent = async (orderId: string) => {
    return this.client.get<OrderFileResponse>(`/p/v1/orders/${orderId}/consent-form/`);
  };

  getRoles = async () => {
    return this.client.get<Role[]>('/v1/roles/');
  };

  getMembers = async () => {
    return this.client.get<Member[]>('/v1/members/');
  };

  createUserKeys = async (env: EnvType) => {
    return this.client.post('/v1/user_keys/', { env_type: env });
  };

  deleteUserKey = async (id: string) => {
    return this.client.delete(`/v1/user_keys/${id}/`);
  };

  createMember = async (data: Omit<CreateMemberResult, 'user_id'>) => {
    return this.client.post<CreateMemberResult>('/v1/members/', data);
  };

  deleteMember = async (user_id: string) => {
    return this.client.delete<void>(`/v1/members/${user_id}/`);
  };

  updateMember = async (
    user_id: string,
    data: {
      first_name: string;
      last_name: string;
      role: string;
      access_to_templates?: string[];
    },
  ) => {
    return this.client.patch<UpdateMemberResult>(`/v1/members/${user_id}/`, data);
  };

  resendInvite = async (user_id: string) => {
    return this.client.post<Member>(`/v1/members/${user_id}/resend/`, {});
  };

  approveMember = async (user_id: string, approved: boolean) => {
    return this.client.patch<void>(`/v1/members/${user_id}/approve/`, {
      is_approved: approved,
    });
  };

  getProperties = async () => {
    return this.client.get<UserProperties>('/v1/user/properties/');
  };

  updateProperties = async (data: Partial<UserProperties>) => {
    return this.client.patch<Partial<UserProperties>>('/v1/user/properties/', data);
  };

  deleteProperties = async (data: { [key: string]: null }) => {
    return this.client.delete<Partial<UserProperties>>('/v1/user/properties/', data);
  };

  impersonate = async (data: ImpersonateParams) => {
    return this.client.post<ImpersonateResponse>('/v1/impersonations/', data);
  };

  getWebhooks = async (params: Partial<PagingFilters>) => {
    // TODO: use public API for webhooks after BE is ready CL-3642
    // return this.client.get<WebhookItem[]>('/p/v1/webhooks/', params);
    return this.client.get<WebhookItem[]>('/v1/webhooks/', params);
  };

  getWebhook = async (id: string) => {
    return this.client.get<WebhookItem>(`/v1/webhooks/${id}/`);
  };

  createWebhook = async (data: CreateWebhookParams) => {
    return this.client.post<WebhookItem>('/v1/webhooks/', data);
  };

  updateWebhook = async (id: string, data: Partial<CreateWebhookParams>) => {
    return this.client.patch<WebhookItem>(`/v1/webhooks/${id}/`, data);
  };

  deleteWebhook = async (id: string) => {
    return this.client.delete<void>(`/v1/webhooks/${id}/`);
  };

  getSupportTickets = (
    params: SupportTicketsRequest = { page_size: 10, page: 1 },
    { signal }: Partial<RequestInit> = {},
  ) => {
    return this.client.get<SupportTicketsResponse>('/v2/support/tickets/', params, { signal });
  };

  uploadSupportTicketAttachment = (file: File) => {
    const formData = new FormData();

    formData.append('file', file, file.name);

    return this.client.post<UploadAttachmentResponse>('/v2/support/tickets/attachments/', formData);
  };

  createSupportTicket = (data: CreateSupportTicketRequest) => {
    return this.client.post<SupportTicketsResult>('/v2/support/tickets/', data);
  };

  getSupportTicketInfo = (ticket_id: string) => {
    return this.client.get<SupportTicketInfoResponse>(`/v2/support/tickets/${ticket_id}/`);
  };

  getSupportTicketComments = (ticket_id: string, params: SupportTicketsRequest = { page_size: 10, page: 1 }) => {
    return this.client.get<SupportTicketCommentsResponse>(`/v2/support/tickets/${ticket_id}/comments/`, params);
  };

  replyToSupportTicket = (ticket_id: string, data: ReplyToSupportTicketRequest) => {
    return this.client.post<SupportTicketCommentsResponse>(`/v2/support/tickets/${ticket_id}/comments/`, data);
  };

  getTemplatesList = (
    params: GetTemplatesRequest = { page_size: 10, page: 1 },
    { signal }: Partial<RequestInit> = {},
  ) => {
    return this.client.get<GetTemplatesResponse>('/p/v1/templates/', params, { signal });
  };

  createTemplate = (data: TemplateSettings) => {
    // change to multipart/form-data for logo file upload
    const formData = serialize(data, {
      dotsForObjectNotation: true,
      noAttributesWithArrayNotation: true,
    });

    return this.client.post<GetTemplateResponse>('/p/v1/templates/', formData);
  };

  updateTemplate = (id: string, data: Partial<TemplateSettings>) => {
    // change to multipart/form-data for logo file upload
    const formData = serialize(data, {
      dotsForObjectNotation: true,
      noAttributesWithArrayNotation: true,
    });

    return this.client.patch<GetTemplateResponse>(`/p/v1/templates/${id}/`, formData);
  };

  getTemplate = (id: string) => {
    return this.client.get<GetTemplateResponse>(`/p/v1/templates/${id}/`);
  };

  deleteTemplate = (id: string) => {
    return this.client.delete<void>(`/p/v1/templates/${id}/`);
  };

  uploadPopularCompaniesList = (file: File) => {
    const formData = new FormData();

    formData.append('file', file, file.name);

    return this.client.post<GetPopularCompaniesListIdResponse>('/p/v1/top-companies/', formData);
  };

  getTwilioSettings = () => {
    return this.client.get<GetTwilioSettings>('/v1/twilio-settings/');
  };

  updateTwilioSettings = (data: UpdateTwilioSettings) => {
    return this.client.patch<GetTwilioSettings>('/v1/twilio-settings/', data);
  };

  deleteTwilioSettings = () => {
    return this.client.delete<GetTwilioSettings>('/v1/twilio-settings/');
  };

  getUploadedDocuments = (linkId: string) => {
    return this.client.get<UploadedDocumentInfo[]>(`/p/v1/links/${linkId}/documents/`);
  };

  getScoringAttributesFile = () => {
    return this.client.get<ScoringAttributesFileResponse>('/v1/scoring-attributes-file/');
  };

  requestCoverage = (data: RequestCoverageParams) => {
    return this.client.post('/v1/coverage-requests/', data);
  };

  getSubcompanies = () => {
    return this.client.get<SubcompaniesResponse>('/v1/subcompanies/');
  };

  getAnalyticsSource = <T>(source: string, params: AnalyticsSourceFilters) => {
    return this.client.get<T>(`/v1/analytics/sources/${source}/`, params);
  };

  createScoringAttributesReport = (data: Record<string, unknown>) => {
    return this.client.post<CreateScoringAttributesReportResponse>('/p/v1/scoring_attributes/reports/', data);
  };

  getScoringAttributesReport = (id: string) => {
    return this.client.get<ScoringAttributesReportResponse>(`/p/v1/scoring_attributes/reports/${id}`);
  };

  getPlatformKeys = <TData>(id: string) => {
    return this.client.get<TData>(`/v1/platform-keys/${id}/`);
  };

  createPlatformKeys = <TData, TVariables>(id: string, data: TVariables) => {
    return this.client.post<TData, TVariables>(`/v1/platform-keys/${id}/`, data);
  };

  updatePlatformKeys = <TData, TVariables>(id: string, data: TVariables) => {
    return this.client.patch<TData, TVariables>(`/v1/platform-keys/${id}/`, data);
  };
}

export const apiClient = new ApiClient(baseApiClient);
